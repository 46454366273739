import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './css/common.scss';
import './css/landing.scss';
import './css/header.scss';
import './css/brand.scss';
import './css/news.scss';
import './css/franchise.scss';
import './css/franchise_bootstrap.scss';
import './css/franchise_new.scss';
import './css/partner.scss';
import './css/checklist.scss';
import './css/store.scss';
import './css/event.scss';
import './css/popup.scss';
import './css/footer.scss';

// import Landing from './routes/Landing';
import Index from './routes/Index';
import Brand from './routes/Brand';
import Franchise from './routes/Franchise';
import NoticeList from './routes/NoticeList';
import NoticeDetail from './routes/NoticeDetail';
import MediaList from './routes/MediaList';
import MediaDetail from './routes/MediaDetail';
import EventList from './routes/EventList';
import EventDetail from './routes/EventDetail';
import Store from './routes/Store';
import Partner from './routes/Partner';
import Event from './routes/Event';
import Checklist from './routes/Checklist';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    return (
      <Router>
        <div id="content">
          <Switch>
            <Route exact path={["", "/"]} component={Index} />
            <Route path={"/index"} component={Index} />
            <Route path={"/brand"} component={Brand} />
            <Route path={"/franchise"} component={Franchise} />
            <Route path={"/news/notice/:id"} component={NoticeDetail} />
            <Route path={"/news/notice"} component={NoticeList} />
            <Route path={"/news/media/:id"} component={MediaDetail} />
            <Route path={"/news/media"} component={MediaList} />
            <Route path={"/news/event/:id"} component={EventDetail} />
            <Route path={"/news/event"} component={EventList} />
            <Route path={"/partner"} component={Partner} />
            <Route path={"/store/:storeType/:storeId"} component={Store} />
            <Route path={"/store/:storeType"} component={Store} />
            <Route path={"/event/:date/:step"} component={Event} />
            <Route path={"/admin/checklist"} component={Checklist} />
          </Switch>
        </div> 
      </Router>
    );
  }
}

export default App;
