/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef, useCallback, Fragment } from "react";
import { useInView } from 'react-intersection-observer';
import "moment/locale/ko";
import { isMobile } from "react-device-detect";
import * as Apis from "../components/Apis";
import * as Common from '../components/Common';
import Header from "./Header";
import Footer from "./FooterNew";
import Loading from '../components/Loading';

import BrandImgPC01 from "../images/main/01_메인.jpg";
import BrandImgPC02 from "../images/main/02_원두소개및메뉴.jpg";
import CompetitivePC01 from "../images/main/03_창업메시지.jpg";
import CompetitivePC02 from "../images/main/04_패스트카페경쟁력_01.jpg";
import CompetitivePC03 from "../images/main/04_패스트카페경쟁력_02.jpg";
import CompetitivePC04 from "../images/main/04_패스트카페경쟁력_03.jpg";
import CompetitivePC05 from "../images/main/04_패스트카페경쟁력_04B.jpg";
import CompetitivePC06 from "../images/main/04_패스트카페경쟁력_05.jpg";

import BrandImgM01 from "../images/m/main/01_메인_MOB01_B.jpg";
import BrandImgM02 from "../images/m/main/01_메인_MOB02_B.jpg";
import BrandImgM03 from "../images/m/main/01_메인_MOB03.jpg";
import BrandImgM04 from "../images/m/main/02_메뉴_MOB.jpg";
import CompetitiveM01 from "../images/m/main/03_창업메시지_MOB_01.jpg";
import CompetitiveM02 from "../images/m/main/03_창업메시지_MOB_02.jpg";
import CompetitiveM03 from "../images/m/main/04_패스트카페경쟁력_MOB_01.jpg";
import CompetitiveM04 from "../images/m/main/04_패스트카페경쟁력_MOB_02.jpg";
import CompetitiveM05 from "../images/m/main/04_패스트카페경쟁력_MOB_03.jpg";
import CompetitiveM06 from "../images/m/main/04_패스트카페경쟁력_MOB_04.jpg";
import CompetitiveM07 from "../images/m/main/04_패스트카페경쟁력_MOB_05B.jpg";
import CompetitiveM08 from "../images/m/main/05_패스트카페경쟁력_MOB_06.jpg";

import ConsultingTel from "../images/franchise/consulting/tel.png";

export default function Index() {
  const headerRef = useRef(null);
  const brandRef = useRef(null);
  const competitiveRef = useRef(null);
  const consultingRef = useRef(null);
  const sectionRefs = {
    Brand: brandRef,
    Competitive: competitiveRef,
    Consulting: consultingRef
  }
  
  const [ isPopupOpen, setIsPopupOpen ] = useState(false);
  const [ isDialogOpen, setIsDialogOpen ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ dialogType, setDialogType ] = useState("Alert");
  const [ dialogMsg, setDialogMsg ] = useState("");
  const [ isAgree, setIsAgree ] = useState(false);
  const [ userName, setUserName ] = useState("");
  const [ userTel, setUserTel ] = useState("");
  const [ isMobile, setIsMobile ] = useState(false);
  const [ userWantArea, setUserWantArea ] = useState("");
  const [ wantType, setWantType ] = useState("");
  const [ isBuildingOwner, setIsBuildingOwner ] = useState(0);
  const [ ask, setAsk ] = useState("");
  const [ section, setSection ] = useState("Brand");
  const [ headerHeight, setHeaderHeight ] = useState(0);

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("html");
    document.getElementsByTagName("body")[0].classList.add("body");
    document.getElementsByTagName("body")[0].classList.add("pb-5");

    let _isMobile = document.getElementsByTagName("body")[0].offsetWidth < 992;

    let mainNav = document.getElementById('Header');
    if(mainNav) {
      document.getElementsByTagName("body")[0].style.paddingTop = mainNav.offsetHeight + "px";
      window.addEventListener("resize", () => {
        let mainNav = document.getElementById('Header');
        document.getElementsByTagName("body")[0].style.paddingTop = mainNav.offsetHeight + "px";
      });

      setHeaderHeight(mainNav.offsetHeight);
    }
    
    setIsMobile(_isMobile);

    window.addEventListener("scroll", _handleScroll);

    return () => {
      window.removeEventListener("scroll", _handleScroll); // 이벤트 정리
    };
  }, []);

  function _handleScroll() {
    let headerY = document.getElementById('Header').offsetHeight;

    const sections = [
      { id: "Brand", ref: brandRef },
      { id: "Competitive", ref: competitiveRef },
      { id: "Consulting", ref: consultingRef },
    ];

    sections.forEach(({ id, ref }) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= headerY && rect.bottom > headerY) {
          setSection(id);
        }
      }
    });
  }

  function _moveSection(section) {
    let _section = section.substr(0, 1).toUpperCase() + section.substr(1);
    setSection(_section);
    let timer = setTimeout(() => {
      const yOffset = -headerHeight; // 헤더 높이만큼 보정
      const y = sectionRefs[_section].current.getBoundingClientRect().top + window.scrollY + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });

      clearTimeout(timer);
    }, 0);
  }

  function _reload() {
    window.location.reload();
  }

  function _doOpenDialog(msg, dialogType = "Alert") {
    setIsDialogOpen(true);
    setDialogMsg(msg);
    setDialogType(dialogType);
  }

  function _doCloseDialog() {
    setIsDialogOpen(false);

    let timer = setTimeout(() => {
      if(dialogType === "Complete" || dialogType === "Cancel") {
        _reload();
      }
      clearTimeout(timer);
    }, 500);
  }

  function _doConsultingConfirm() {
    window.gtag_franchise('패스트카페 상담신청');

    _doOpenDialog("상담신청을 하시겠습니까?", "Confirm");
  }

  function _doConsulting() {
    let _userName = userName;
    if(!_userName) {
      _doOpenDialog("성명을 입력해주세요.");
      return;
    }

    let _userTel = userTel;
    if(!_userTel) {
      _doOpenDialog("휴대전화번호를<br/>입력해주세요.");
      return;
    }
    if(!Common.isPhoneNumberValidation(_userTel)) {
      _doOpenDialog("휴대전화번호 형식에<br/>맞지 않습니다.");
      return;
    }
    let _userTel1 = _userTel.substring(0, 3);
		let _userTel2 = _userTel.substring(3, _userTel.length === 10 ? 6 : 7);
		let _userTel3 = _userTel.substring(_userTel.length === 10 ? 6 : 7, _userTel.length === 10 ? 10 : 11);

    let _ask = "";
    if(wantType) {
      _ask = "희망창업형태 : " + wantType + "<br/>";
    }
    _ask += ask;

    if(!isAgree) {
      _doOpenDialog("개인정보 수집 및 이용에<br/>동의해주세요.");
      return;
    }

    setIsDialogOpen(false);
    setIsLoading(true);
    Apis.insertFranchise("FASTCAFE", _userName, _userTel1, _userTel2, _userTel3, "", "", userWantArea, isBuildingOwner, _ask).then(({ loading, data: { result, resultCode, resultMessage } }) => {
      setIsLoading(false);
			if (resultCode === "S1000") {
        window.wcsLog();  // 네이버 검색 전환코드 실행
        window.gtag_report_conversion(); // gtag
        window.gtag_report_conversion_mirae(); // gtag
        window.gtag_mirae(); // gtag
        window.kakaoMoment();
        // _doOpenDialog("신청이 완료되었습니다.", "Complete");
			} else {
				// _doOpenDialog("문제가 발생하였습니다.<br/>다시 시도 해주세요.");
      }
      _doOpenDialog("신청이 완료되었습니다.", "Complete");
    });
  }

  function _doConsultingCancel() {
    _doOpenDialog("상담접수를<br/>취소하시겠습니까?", "Cancel");
  }

  function _componentRender() {
    if(!isMobile) {
      return (
        <Fragment>
          <section id="Brand" className="container" ref={brandRef}>
            <img src={ BrandImgPC01 } className="w-100" alt="" />  
            <img src={ BrandImgPC02 } className="w-100" alt="" />  
          </section>

          <section id="Competitive" className="container" ref={competitiveRef}>
            <img src={ CompetitivePC01 } className="w-100" alt="" />
            <img src={ CompetitivePC02 } className="w-100" alt="" />
            <img src={ CompetitivePC03 } className="w-100" alt="" />
            <img src={ CompetitivePC04 } className="w-100" alt="" />
            <img src={ CompetitivePC05 } className="w-100" alt="" />
            <img src={ CompetitivePC06 } className="w-100" alt="" />
          </section>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <section id="Brand" className="container" ref={brandRef}>
            <img src={ BrandImgM01 } className="w-100" alt="" />  
            <img src={ BrandImgM02 } className="w-100" alt="" />  
            <img src={ BrandImgM03 } className="w-100" alt="" />  
            <img src={ BrandImgM04 } className="w-100" alt="" />  
          </section>

          <section id="Competitive" className="container" ref={competitiveRef}>
            <img src={ CompetitiveM01 } className="w-100" alt="" />
            <img src={ CompetitiveM02 } className="w-100" alt="" />
            <img src={ CompetitiveM03 } className="w-100" alt="" />
            <img src={ CompetitiveM04 } className="w-100" alt="" />
            <img src={ CompetitiveM05 } className="w-100" alt="" />
            <img src={ CompetitiveM06 } className="w-100" alt="" />
            <img src={ CompetitiveM07 } className="w-100" alt="" />
            <img src={ CompetitiveM08 } className="w-100" alt="" />
          </section>
        </Fragment>
      )
    }
  }
  
  function _doInquiryWithGtag(eventName) {
    window.gtag_franchise('패스트카페 ' + eventName);
    if(eventName.indexOf('전화문의') > -1) {
      document.location.href = "tel:1661-0227";
    } else if(eventName === '카톡문의') {
      window.open('http://pf.kakao.com/_cGips/chat', '_blank')
    }
  }

  return (
    <div id="FranchiseNewComponent" style={{ overflowX: "hidden" }}>
      <Header type="Index" ref={headerRef} section={section} onClick={e => _moveSection(e)} />

      { _componentRender() }

      <section id="Consulting" className="Consulting page-section border-bottom-0" ref={consultingRef}>
        <div className="container text-center my-5">
          <h2 className="text-center">상담접수</h2>
          <h5 className="mt-3 text-center text-keep">상담 신청 정보를 남겨주시면 빠른 시일 내에 연락 드리겠습니다.</h5>
          <h4 className="mt-3 text-center text-keep PC">대표전화 1661-0227</h4>

          <div className="MainContent">
            <div className="Request">
              <p className="MainTitle fs-3 text-start">신청자 정보 <span>(* 필수입력 항목)</span></p>
              <div className="Content">
                <div className="Row">
                  <div className="Title fs-4">성명 *</div>
                  <div className="Input fs-4">
                    <input type="text" name="name" onKeyUp={e => setUserName(e.target.value)} />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">휴대전화 *</div>
                  <div className="Input fs-4">
                    <input type="tel" maxLength="11" onInput={e => setUserTel(Common.replaceNum(e.target.value))} value={ userTel } />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">설치장소 확보 여부</div>
                  <div className="Radio text-start isBuildingOwner">
                    <label className="Label fs-4"> 있음
                      <input type="radio" name="isBuildingOwner" defaultChecked onChange={() => setIsBuildingOwner(0)} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 아직없음
                      <input type="radio" name="isBuildingOwner" onChange={() => setIsBuildingOwner(1)} />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">설치 예정 장소</div>
                  <div className="Input fs-4">
                    <input type="text" onKeyUp={e => setUserWantArea(e.target.value)} />
                  </div>
                </div>
                <div className="Row">
                  <div className="Title fs-4">상담 주제</div>
                  <div className="Radio text-start">
                    <label className="Label fs-4"> 커피머신 단독 구매
                      <input type="radio" name="wantTime" defaultChecked onChange={() => setWantType("커피머신 단독 구매")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 창업
                      <input type="radio" name="wantTime" onChange={() => setWantType("창업")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4"> 사내카페
                      <input type="radio" name="wantTime" onChange={() => setWantType("사내카페")} />
                      <span className="Checkmark"></span>
                    </label>
                    <label className="Label fs-4 fw-bold"> 입점제안
                      <input type="radio" name="wantTime" onChange={() => setWantType("입점제안")} />
                      <span className="Checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="Row">
                  <textarea name="ask" className="fs-5" placeholder="기타 전달 말씀, 문의사항 있으신 경우 자유롭게 기재 해 주세요."  onKeyUp={e => setAsk(e.target.value)}></textarea>
                </div>
              </div>
            </div>
            <div className="Privacy">
              <p className="MainTitle fs-3 text-start">개인정보 수집 및 이용안내</p>
              <div className="Content">
                <div className="Row">
                  <p className="text-start fs-4">
                    아래와 같이 고객님의 개인정보를 수집하고 있습니다.<br/><br/>
                    <span>개인정보 수집범위 :</span> 성명, 휴대전화, 문의내용<br/>
                    <span>개인정보 수집 및 이용목적 :</span> 상담 신청 및 조회<br/>
                    <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로
                    개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며
                    보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
                  </p>
                </div>
                <div className="Row text-start">
                  <label className="Label fs-4"> 개인정보 수집 및 이용에 동의합니다.
                    <input type="checkbox" name="isAgree" value="true" onChange={() => setIsAgree(!isAgree)} />
                    <span className="Checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="BtnArea">
                  <button className="fs-4 py-3" onClick={() => _doConsultingConfirm()}>신청</button>
                  <button className="fs-4 py-3" onClick={() => _doConsultingCancel()}>취소</button>
                </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <section id="ConsultingBottom" className="page-section p-1 p-md-3 fixed-bottom">
        <div className="InquiryMenuBarWrap d-lg-none d-flex flex-column align-items-end text-primary">
          <button className="tel d-flex justify-content-center align-items-center flex-column" onClick={() => _doInquiryWithGtag('전화문의')}>
            <img src={ConsultingTel} className="img-fluid" alt="전화문의" />
          </button>
        </div>
      </section>

      <div id="Layer" className={"LayerWrap " + (isPopupOpen ? "on" : "" )}>
        <div className="PopLayer">
          <div className="PopContainer">
            <p className="Title">개인정보 수집 및 이용안내</p>
            <p className="Sub">패스트카페 창업문의신청 시 아래와 같이 고객님의 개인정보를 수집하고 있습니다.</p>
            <p className="Desc">
              <span>개인정보 수집범위 :</span> 이름, 휴대전화번호<br/>
              <span>개인정보 수집 및 이용목적 :</span> 창업문의 신청 확인 및 조회<br/>
              <span>개인정보 수집 및 보유기간 :</span> 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기하며 보유기간은 최대 3년을 넘기지 않는 것을 원칙으로 한다.
            </p>
          </div>
          <button className="BtnClose" onClick={() => setIsPopupOpen(false)}></button>
        </div>
      </div>

      <div id="Dialog" className={"LayerWrap " + (isDialogOpen ? "on" : "" )}>
        <div className="PopLayer">
          <div className="PopContainer">
            <p className="Msg" dangerouslySetInnerHTML={{ __html: dialogMsg }}></p>
          </div>
          <div className="BtnGroup">
            <button className="Ok" onClick={() => dialogType === "Confirm" ? _doConsulting() : _doCloseDialog()}>확인</button>
            <button className={"Cancel " + dialogType} onClick={() => _doCloseDialog()}>취소</button>
          </div>
          <button className="BtnClose" onClick={() => _doCloseDialog()}></button>
        </div>
      </div>

      { isLoading && <Loading /> }
    </div> 
  )
}