import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import * as Common from "../components/Common";
import * as Apis from "../components/Apis";
import Slider from "react-slick";
import Loading from '../components/Loading';
import Header from "./Header";
import Footer from "./FooterNew";

import Story1 from "../images/brand/img-story1.jpg";
import Story2 from "../images/brand/img-story2.jpg";
import Story3 from "../images/brand/img-story3.jpg";
import Story4 from "../images/brand/img-story4.jpg";
import StoreBg from "../images/brand/bg-store.jpg";
import NewBg from "../images/brand/bg-news_new.png";
import Menu1 from "../images/brand/menu1.png";
import Menu2 from "../images/brand/menu2.png";
import Menu3 from "../images/brand/menu3.png";
import Menu4 from "../images/brand/menu4.png";
import Menu5 from "../images/brand/menu5.png";
import Menu6 from "../images/brand/menu6.png";
import Menu7 from "../images/brand/menu7.png";
import BtnFranchise from "../images/brand/btn-franchise.png";
import BtnInvestment from "../images/brand/btn-investment.png";
import BtnConsulting from "../images/brand/btn-consulting.png";
import ArrowLeft from "../images/brand/arrow-top-left.png";
import ArrowRight from "../images/brand/arrow-top-right.png";

import LayerPopup from "./LayerPopup";

const BrandMap = [
  { false: require('../images/brand/brand1.png'), true: require('../images/brand/m/brand1.png') },
  { false: require('../images/brand/brand2.png'), true: require('../images/brand/m/brand2.png') },
  { false: require('../images/brand/brand3.png'), true: require('../images/brand/m/brand3.png') },
  { false: require('../images/brand/brand4.png'), true: require('../images/brand/m/brand4.png') },
];

const settingsForTitleBg = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 4000,
  useCSS: true,
  touchMove: true,
  pauseOnHover: true,
  easing: 'ease-in-out',
  fade: true
};

const settingsForBrand = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  useCSS: true,
  touchMove: true,
  pauseOnHover: true,
  // easing: 'ease-in-out'
  fade: true
};

const settingsForMenu = {
  arrows: false,
  infinite: true,
  speed: 700,
  autoplay: true,
  autoplaySpeed: 3000,
  useCSS: true,
  touchMove: true,
  pauseOnHover: true
};

class Brand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleCurrIdx: 0,
      titleNextIdx: 0,
      brandCurrIdx: 0,
      brandNextIdx: 0,
      classForBrand: "on",
      menuCurrIdx: 0,
      menuNextIdx: 0,
      isPopupOpen: false,
      newsList: null,
      section: "Home",
      isMobile: false,
      banners: null,
      popups: null,
    };
  }

  _beforeChangeForTitle(before, next) {
    this.setState({ titleNextIdx: next });
  }

  _afterChangeForTitle(idx) {
    this.setState({ titleCurrIdx: idx });
  }

  _beforeChangeForBrand(before, next) {
    this.setState({
      brandNextIdx: next,
      classForBrand: "off"
    });
  }

  _afterChangeForBrand(idx) {
    this.setState({
      brandCurrIdx: idx,
      classForBrand: "on",
     });
  }

  _beforeChangeForMenu(before, next) {
    this.setState({ menuNextIdx: next });
  }

  _afterChangeForMenu(idx) {
    this.setState({ menuCurrIdx: idx });
  }

  _listMain() {
    Apis.listMain().then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        this.setState({ newsList: result.entities });
			} else {
				console.log(resultMessage);
      }
    });
  };

  componentDidMount() {
    let _hash = this.props.location.hash;
    if(_hash) {
      this._moveSection(_hash.substr(1));
    }

    this.setState({ isMobile: isMobile });

    this._listMain();

    this._listBanner();
  }

  _listBanner() {
    Apis.listBanner("", "brand", 999).then(({ loading, data: { result, resultCode, resultMessage } }) => {
			if (resultCode === "S1000") {
        let _banners = [];
        let _popups = [];
        if(result.entities.length !== 0 && result.entities !== null) {
          for(let idx in result.entities) {
            let _entity = result.entities[idx];
            if(_entity.type === "banner") {
              _banners.push(_entity);
            } else if(_entity.type === "popup") {
              _popups.push(_entity);
            }
          }

          this.setState({
            banners: _banners,
            popups: _popups
           });
        }
			} else {
				console.log(resultMessage);
      }
    });
  }

  _onScroll = (e) => {
    let scrollTop = this.Container.scrollTop;

    let HomeStartPos = this.Home.getBoundingClientRect().y;
    let HomeEndPos = this.Story.getBoundingClientRect().top;
    let StoryStartPos = this.Story.getBoundingClientRect().y;
    let StoryEndPos = this.Brand.getBoundingClientRect().top;
    let BrandStartPos = this.Brand.getBoundingClientRect().y;
    let BrandEndPos = this.Menu.getBoundingClientRect().top;
    let MenuStartPos = this.Menu.getBoundingClientRect().y;
    let MenuEndPos = this.Store.getBoundingClientRect().top;
    let StoreStartPos = this.Store.getBoundingClientRect().y;
    let StoreEndPos = this.News.getBoundingClientRect().top;
    let NewsStartPos = this.News.getBoundingClientRect().y;

    if(HomeStartPos < scrollTop < HomeEndPos) {
      this.setState({ section: "Home" });
    } else if(StoryStartPos < scrollTop < StoryEndPos) {
      this.setState({ section: "Story" });
    } else if(BrandStartPos < scrollTop < BrandEndPos) {
      this.setState({ section: "Brand" });
    } else if(MenuStartPos < scrollTop < MenuEndPos) {
      this.setState({ section: "Menu" });
    } else if(StoreStartPos < scrollTop < StoreEndPos) {
      this.setState({ section: "Store" });
    } else if(NewsStartPos < scrollTop) {
      this.setState({ section: "News" });
    }
  };

  _moveSection(section) {
    let _section = section.substr(0, 1).toUpperCase() + section.substr(1);
    this.setState({ section: _section });
    let timer = setTimeout(() => {
      if(this[_section].scrollIntoView) {
        this[_section].scrollIntoView({ behavior: "smooth" });
      }
      clearTimeout(timer);
    }, 0);
  }

  _gotoTop() {
    window.location.href = "#Home";
  }

  render() {
    return (
      <div id="BrandComponent">
        <Header type="BRAND" section={this.state.section} onClick={e => this._moveSection(e)} />

        <div className="Container" ref={ref => {this.Container = ref}} onScroll={() => this._onScroll()}>
          <div id="Home" className="Anchor" ref={ref => {this.Home = ref}}></div>
          <div className="Home">
            <Slider ref={ref => this.titleBgSlide = ref} afterChange={(idx) => this._afterChangeForTitle(idx)} beforeChange={(current, next) => this._beforeChangeForTitle(current, next)} {...settingsForTitleBg}>
              { this.state.banners && this.state.banners.map(( entity, i ) => {
                if(entity.linkUrl) {
                  return <a href={ entity.linkUrl } key={i} target={ "_" + entity.target }><img className="TopBg" src={ this.state.isMobile ? entity.mobileImgUrl : entity.pcImgUrl } alt="" /></a>
                } else {
                  return <img key={i} className="TopBg" src={ this.state.isMobile ? entity.mobileImgUrl : entity.pcImgUrl } alt="" />
                }
              }) }
            </Slider>
            <button className="ArrowLeft" onClick={() => this.titleBgSlide.slickPrev()}><img src={ArrowLeft} alt="" /></button>
            <button className="ArrowRight" onClick={() => this.titleBgSlide.slickNext()}><img src={ArrowRight} alt="" /></button>
          </div>
          <div id="Story" className="Anchor" ref={ref => {this.Story = ref}}></div>
          <div className="Story">
            <div className="SubContainer">
              <div className="MainContent">
                <div className="Wrap">
                  <div className="TitleWrap">
                    <p>BRAND STORY</p>
                  </div>

                  <div className="Content">
                    <div className="ContentWrap">
                      <div className="ImgWrap">
                        <img src={Story1} alt="" />
                        <div className="ImgBg"></div>
                      </div>
                      <div className="DescWrap">
                        <p className="Title">철학이 있는 커피</p>
                        <p className="Desc">공간 서비스 그룹 토즈는 ‘고객의 목적과 가치가 존중되고 실현되는 최적의 공간 서비스를 제공한다'라는 기업 철학 아래, 2001년부터 고객을 위한 최적의 서비스를 끊임없이 연구하였습니다. 서비스 핵심 요소 중의 하나인 음료가 단순히 ‘마시는 것’이 아니라 공간 이용의 목적과 시간 속에서 다양한 의미를 갖게 된다는 것을 깨달았습니다. 때로는 집중을, 때로는 휴식을, 때로는 그 자체를… 토즈는 ‘커피'에도 기업의 철학을 담고 싶었습니다.</p>
                      </div>
                    </div>
                    <div className="ContentWrap">
                      <div className="ImgWrap">
                        <img src={Story2} alt="" />
                        <div className="ImgBg"></div>
                      </div>
                      <div className="DescWrap">
                        <p className="Title">대중의 맛을 찾기 위한 천만 잔의 실험,<br/>그리고 패스트카페 by TOZ의 탄생</p>
                        <p className="Desc">2012년, 강남에 스마트카페를 오픈하면서 본격적으로 커피 맛을 연구하기 시작했습니다. 우리를 경험한 천만 명의 고객이 즐겼던 커피, 수만 명에게 받은 피드백으로 토즈에 가장 어울리는 커피를 완성했습니다. 가장 대중적이면서 특별함을 담은 스페셜티 커피. 토즈의 맛을 보다 많은 분께 알리고자 패스트카페를 만들었습니다.</p>
                      </div>
                    </div>
                  </div>
                  <div className="Content">
                    <div className="ContentWrap">
                      <div className="ImgWrap">
                        <img src={Story3} alt="" />
                        <div className="ImgBg"></div>
                      </div>
                      <div className="DescWrap">
                        <p className="Title">대중적인 맛과 특별한 맛의 공존</p>
                        <p className="Desc">토즈가 고객을 위해 맛을 연구하듯, 많은 로스터리 카페도 특별한 커피의 맛을 찾기 위해 노력합니다. 추구하는 가치는 다를 수 있지만 고객 만족을 향한 가치는 같다고 생각합니다. 패스트카페는 토즈의 가치가 담긴 커피 외에도 전국 유명 카페들의 개성 있는 맛도 함께 즐길 수 있습니다.</p>
                      </div>
                    </div>
                    <div className="ContentWrap">
                      <div className="ImgWrap">
                        <img src={Story4} alt="" />
                        <div className="ImgBg"></div>
                      </div>
                      <div className="DescWrap">
                        <p className="Title">균일한 맛, 높은 수준의 커피</p>
                        <p className="Desc">같은 원두라도 만드는 사람과 환경에 따라 그 맛은 천차만별입니다. 패스트카페는 고객에게 항상 균일한 맛을 서비스하기 위해 전문적인 자동 커피머신을 사용합니다. 또한 최고의 맛을 구현하기 위해 커피의 본고장 유럽에서 수십 년간의 연구로 개발한 스위스산 핵심 부품을 고집하여 적용하였습니다.</p>
                      </div>
                    </div>
                  </div>

                  <div className="TitleWrap">
                    <div className="Logo"></div>
                    <p>‘고객의 목적과 가치가 존중되고 실현되는 최적의 서비스를 제공한다’라는 토즈의 마음을 담았습니다. 패스트카페는 토즈의 철학을 커피로 구현하는 공간입니다.</p>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div id="Brand" className="Anchor" ref={ref => {this.Brand = ref}}></div>
          <div className="Brand">
            <div className="SubContainer">
              <div className="MainContent">
                <ul className="NavContainer">
                  {[...Array(4)].map((n, idx) => {
                    return <li key={idx}><button className={ this.state.brandNextIdx === idx ? "active" : "" } onClick={() => this.brandSlide.slickGoTo(idx)}></button></li>
                  })}
                </ul>
                <Slider ref={ref => this.brandSlide = ref} afterChange={(idx) => this._afterChangeForBrand(idx)} beforeChange={(current, next) => this._beforeChangeForBrand(current, next)} className="Slide" {...settingsForBrand}>
                  { BrandMap.map((obj, idx) => { return <img key={idx} className="BrandImg" src={obj[this.state.isMobile]} alt="" /> }) }
                </Slider>
              </div>
            </div>  
          </div>
          <div id="Menu" className="Anchor" ref={ref => {this.Menu = ref}}></div>
          <div className="Menu">
            <div className="SubContainer">
              <div className="MainContent">
                <ul className="NavContainer">
                  <li>
                    <button className={ this.state.menuNextIdx === 0 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(0)}></button>
                  </li>
                  <li>
                    <button className={ this.state.menuNextIdx === 1 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(1)}></button>
                  </li>
                  <li>
                    <button className={ this.state.menuNextIdx >= 2 && this.state.menuNextIdx <= 5 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(2)}></button>
                  </li>
                  <li>
                    <button className={ this.state.menuNextIdx === 6 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(6)}></button>
                  </li>
                </ul>
                <Slider ref={ref => this.menuSlide = ref} afterChange={(idx) => this._afterChangeForMenu(idx)} beforeChange={(current, next) => this._beforeChangeForMenu(current, next)} className="Slide" {...settingsForMenu}>
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST COFFEE</p>
                      <p className="Desc">갓 로스팅한 프리미엄 원두를 사용하여 다양한 음료를 제조합니다.</p>
                      <p className="Desc">풍부한 맛과 향은 물론 간편한 사용성과 빠른 속도까지 즐겨보세요.</p>
                      <p className="TitleSub">MENU</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">아메리카노 (HOT/ICED)</p>
                          <p className="MenuTitle">바닐라라떼 (HOT/ICED)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">카페라떼 (HOT/ICED)</p>
                          <p className="MenuTitle">카페모카 (HOT/ICED)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">카라멜마끼아또 (HOT/ICED)</p>
                        </div>
                      </div>
                      <p className="Caution">* 원두 종류와 메뉴는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                    </div>
                  </div> 
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">NON COFFEE</p>
                      <p className="Desc">커피를 좋아하지 않거나, 카페인을 너무 많이 마셔서 부담될 때 커피 외에도 다양한 종류의 음료를 간편하고  빠르게 즐길 수 있습니다.</p>
                      <p className="TitleSub">MENU</p>
                      <div className="MenuWrap">
                      <div>
                          <p className="MenuTitle">녹차라떼 (HOT)</p>
                          <p className="MenuTitle">청포도에이드 (ICE)</p>
                          <p className="MenuTitle">핫초코 (HOT)</p>
                          <p className="MenuTitle">초코나무라떼 (HOT)</p>
                          <p className="MenuTitle">복숭아홍차 (HOT)</p>
                          <p className="MenuTitle">얼음컵</p>
                        </div>
                        <div>
                          <p className="MenuTitle">녹차라떼 (ICE)</p>
                          <p className="MenuTitle">자몽에이드 (ICE)</p>
                          <p className="MenuTitle">아이스초코 (ICE)</p>
                          <p className="MenuTitle">초코나무라떼 (ICE)</p>
                          <p className="MenuTitle">복숭아아이스티 (ICE)</p>
                        </div>
                      </div>
                      <p className="Caution">* 메뉴는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                    </div>
                  </div> 
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST CELEB CAFE</p>
                      <p className="Desc">핫플레이스 카페의 네임드 로스터와 바리스타의 자존심을 건 시그니처 블렌드를 캔, 파우치, 드립백, NON COFFEE 형태로 제공합니다.</p>
                      <p className="TitleSub">BRAND</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">모모스커피 (3종)</p>
                          <p className="MenuTitle">헤베커피 (3종)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">프릳츠커피 (2종)</p>
                          <p className="MenuTitle">빈브라더스 (1종)</p>
                        </div>
                      </div>
                      <p className="Caution">* 브랜드 및 제품 종류는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                    </div>
                  </div>
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST CELEB CAFE</p>
                      <p className="Desc">핫플레이스 카페의 네임드 로스터와 바리스타의 자존심을 건 시그니처 블렌드를 캔, 파우치, 드립백, NON COFFEE 형태로 제공합니다.</p>
                      <p className="TitleSub">BRAND</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">모모스커피 (3종)</p>
                          <p className="MenuTitle">헤베커피 (3종)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">프릳츠커피 (2종)</p>
                          <p className="MenuTitle">빈브라더스 (1종)</p>
                        </div>
                      </div>
                      <p className="Caution">* 브랜드 및 제품 종류는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                      {/* <button className="More">more</button> */}
                    </div>
                  </div> 
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST CELEB CAFE</p>
                      <p className="Desc">핫플레이스 카페의 네임드 로스터와 바리스타의 자존심을 건 시그니처 블렌드를 캔, 파우치, 드립백, NON COFFEE 형태로 제공합니다.</p>
                      <p className="TitleSub">BRAND</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">파우치커피 (6종)</p>
                        </div>
                        <div></div>
                      </div>  
                      <p className="Caution">* 브랜드 및 제품 종류는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                      {/* <button className="More">more</button> */}
                    </div>
                  </div> 
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST CELEB CAFE</p>
                      <p className="Desc">핫플레이스 카페의 네임드 로스터와 바리스타의 자존심을 건 시그니처 블렌드를 캔, 파우치, 드립백, NON COFFEE 형태로 제공합니다.</p>
                      <p className="TitleSub">BRAND</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">오틀리 (1종)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">청춘전투 (3종)</p>
                        </div>
                      </div>
                      <p className="Caution">* 브랜드 및 제품 종류는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                      {/* <button className="More">more</button> */}
                    </div>
                  </div>
                  <div className="PageContainer">
                    <div className="PageWrap">
                      <p className="Title">FAST CELEB DESSERT</p>
                      <p className="Desc">간식 하나를 먹어도 건강하게 먹을 수 있게 엄격한 기준을 통해 선별한 맛있는 건강 간식을 제공합니다.</p>
                      <p className="TitleSub">MENU</p>
                      <div className="MenuWrap">
                        <div>
                          <p className="MenuTitle">잇츠베러 크레커 (1종)</p>
                          <p className="MenuTitle">아임닭 꾸이칩 (1종)</p>
                        </div>
                        <div>
                          <p className="MenuTitle">아임웰 베이글칩 (1종)</p>
                        </div>
                      </div>
                      <p className="Caution">* 브랜드 및 제품 종류는 지점별/시즌별 차이가 있을 수 있습니다.</p>
                      {/* <button className="More">more</button> */}
                    </div>
                  </div>
                </Slider>
                <div className="Right">
                  <div className="Wrap">
                    <ul className={ this.state.menuNextIdx >= 2 && this.state.menuNextIdx <= 6 ? "on" : "off" }>
                      <li className={ this.state.menuNextIdx === 2 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(2)}>
                        <button>RTD</button>
                      </li>
                      <li className={ this.state.menuNextIdx === 3 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(3)}>
                        <button>드립백</button>
                      </li>
                      <li className={ this.state.menuNextIdx === 4 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(4)}>
                        <button>파우치</button>
                      </li>
                      <li className={ this.state.menuNextIdx === 5 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(5)}>
                        <button>NON COFFEE</button>
                      </li>
                      <li className={ this.state.menuNextIdx === 6 ? "active" : "" } onClick={() => this.menuSlide.slickGoTo(5)}>
                        <button>DESSERT</button>
                      </li>
                    </ul>
                    <div className="ElementWrap">
                      <div className={"Element " + (this.state.menuNextIdx === 0 ? "on" : "off")}>
                        <img src={ Menu1 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트커피 머신</span>
                          <span className="Sub">(Fast Coffee Machine)</span>
                        </p>
                        <p>
                          <span className="Title">추출방식 : </span>
                          <span className="Content">Espresso</span>
                        </p>
                        <p className="Desc">고압력으로 90℃ 전후에서 20초 내 30ml의 에스프레소를 추출하기 때문에 카페인이 적고 커피의 순수한 맛을 느낄 수 있습니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 1 ? "on" : "off")}>
                        <img src={ Menu5 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트커피 머신</span>
                          <span className="Sub">(Fast Coffee Machine)</span>
                        </p>
                        <p className="Desc">메뉴 고유의 맛을 위해 다양한 재료를 엄격한 기준으로 선별하여 패스트카페만의 레시피로 제조합니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 2 ? "on" : "off")}>
                        <img src={ Menu2 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트셀럽카페</span>
                          <span className="Sub">(Fast Celeb Cafe)</span>
                        </p>
                        <p>
                          <span className="Title">추출방식 : </span>
                          <span className="Content">Cold Brew</span>
                        </p>
                        <p className="Desc">열을 가하지 않고 차가운 물로 천천히 우려낸 커피를 캔으로 제조하여 어디서든 스페셜티 원두의 맛을 간편하게 즐길 수 있습니다. 콜드브루의 특징상 산미와 쓴맛이 덜하고 카페인이 높습니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 3 ? "on" : "off")}>
                        <img src={ Menu4 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트셀럽카페</span>
                          <span className="Sub">(Fast Celeb Cafe)</span>
                        </p>
                        <p className="Desc">원두 분쇄 직후 질소충전포장으로 산소와 접촉을 완벽 차단하여 일반 원두보다 오랜 시간 신선한 향과 맛을 머금고 있어 유명 로스터리 카페의 풍미를 최대한 그대로 즐길 수 있습니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 4 ? "on" : "off")}>
                        <img src={ Menu3 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트셀럽카페</span>
                          <span className="Sub">(Fast Celeb Cafe)</span>
                        </p>
                        <p>
                          <span className="Title">추출방식 : </span>
                          <span className="Content">Hot Brew</span>
                        </p>
                        <p className="Desc">파우치 형태의 액상커피로 되어 휴대성이 좋고, 아이스 커피로 총 3잔 정도의 양이 들어 있어 가성비 좋은 제품입니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 5 ? "on" : "off")}>
                        <img src={ Menu6 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트셀럽카페</span>
                          <span className="Sub">(Fast Celeb Cafe)</span>
                        </p>
                        <p className="Desc">티(Tea)탄산, 저칼로리, 락토프리, 비건인증 음료 등 커피를 희망하지 않는 고객님을 위한 특별한 음료가 준비되어 있습니다.</p>
                      </div>
                      <div className={"Element " + (this.state.menuNextIdx === 6 ? "on" : "off")}>
                        <img src={ Menu7 } alt="" />
                        <p className="FirstRow">
                          <span className="Title">사용머신 : </span>
                          <span className="Content">패스트셀럽카페</span>
                          <span className="Sub">(Fast Celeb Cafe)</span>
                        </p>
                        <p className="Desc">스낵을 하나 먹어도 건강하게 드시고 싶은 고객님을 위해, 튀기지 않고 오븐에 구운 건강한 간식이 준비되어 있습니다.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>  
          </div>
          <div id="Store" className="Anchor" ref={ref => {this.Store = ref}}></div>
          <div className="Store">
            <div className="SubContainer">
              <img src={StoreBg} alt="서울시 강남구 역삼동 647-8 1층" />
              <div className="MainContent">
                <div className="Content">
                  <p className="Title">멀리 가지 않아도 돼요.</p><br/>
                  <p className="Title Bold">전국 각지의 소문난 커피를</p>
                  <p className="Title Bold">더 편하게 맛볼 수 있도록</p>
                  <p className="Title">패스트카페가 담았습니다.</p><br/>
                  <p className="Title">깊이 탐미하며 만난 커피들 사이에서</p>
                  <p className="Title">고소한 한 모금에 기분 좋아지는</p>
                  <p className="Title">커피만을 찾아 소개합니다.</p>
                  <Link to="/store/all" className="Location">가맹점 찾기</Link>
                </div>
              </div>
            </div>
          </div>
          <div id="With" className="Anchor" ref={ref => {this.With = ref}}></div>
          <div className="With">
            <div className="SubContainer">
              <div className="MainContent">
                <div className="Wrap">
                  <div className="Left">
                    <p className="Title">With FAST CAFE</p>
                    <p className="Desc1">패스트카페와 함께할 가맹점주님을 모집합니다.</p>
                    <p className="Desc2">가맹상담  1668-5175</p>
                    <p className="Desc3">(상담시간 - 평일 09:30 ~ 18:00)</p>
                  </div>
                  <div className="Right">
                    <Link to="/franchise">
                      <button className="Franchise">
                        <img src={ BtnFranchise } alt="" />
                        <p>가맹안내</p>
                      </button>
                    </Link>
                    <Link to="/franchise#Competitive">
                      <button className="Investment">
                        <img src={ BtnInvestment } alt="" />
                        <p>경쟁력</p>
                      </button>  
                    </Link>
                    <Link to="/franchise#Consulting">
                      <button className="Consulting">
                        <img src={ BtnConsulting } alt="" />
                        <p>상담신청</p>
                      </button>  
                    </Link>
                  </div>
                </div>  
              </div>
            </div>
          </div>
          <div id="News" className="Anchor" ref={ref => {this.News = ref}}></div>
          <div className="News">
            <div className="SubContainer">
              <div className="MainContent">
                <div className="Left">
                  <img src={ NewBg } alt="" />
                  {/* <div className="TitleWrap">
                    <p>Take, Moments</p>
                    <p>FAST CAFE</p>
                  </div> */}
                </div>
                <div className="Right">
                  <div className="SubWrap">
                    <div className="TitleWrap">
                      <p className="Title">
                        <span className="News">NEWS</span>
                        <span className="Event">&MEDIA</span>
                        <Link to="/news/notice" className="More"><span>더보기</span></Link>
                      </p>
                      <div className="TitleLine"></div>
                    </div>  
                    { this.state.newsList ? 
                      <ul>
                        { this.state.newsList.map((entity, idx) => (
                            <li key={idx}>
                              <a href={entity.linkUrl ? entity.linkUrl : ("/news/" + ( entity.type.toLowerCase() === "event" ? "store" : entity.type.toLowerCase() ) + "/" + entity.id)} target={ entity.linkUrl ? "_blank" : "_self" } rel="noreferrer">
                                <span className="Type">{ Common.convertNewsTypeName(entity.type) }</span>
                                <span className="Title">{ entity.title }</span>
                                <span className="Date">{ entity.regdate }</span>
                              </a>
                            </li> 
                          ))
                        }
                      </ul> : <ul><li><p className="Nodata">등록된 게시물이 없습니다.</p></li></ul>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="FranchiseNewComponent">
            <Footer />
          </div>
        </div>

        { this.state.popups && this.state.popups.length !== 0 && <LayerPopup datas={this.state.popups} onClick={this._bannerClick} /> }

        { this.state.isLoading && <Loading /> }
      </div> 
    )
  }
}

export default Brand;
